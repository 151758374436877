import React, { useState } from 'react';
import { FaRegComment } from 'react-icons/fa';
import ReactStars from 'react-stars';

const ProductCard = ({
    product,
    relatedProducts,
    cart,
    setCart,
    userData,
    setClicked,
}) => {
    const [isInCart, setIsInCart] = useState(false);
    const [isInWishlist, setIsInWishlist] = useState(false);

    const [isServiceOrder, setIsServiceOrder] = useState(false);
    const [rating, setRating] = useState(0);

    const handleAddToCart = () => {
        setIsInCart(true);
        // Add product to cart
    };

    const handleAddToWishlist = () => {
        setIsInWishlist(true);
    };

    const handleServiceOrder = () => {
        if (userData) {
            setIsServiceOrder(true);

            if (isServiceOrder) {
                const newCart = [...cart];
                newCart.push(product);
                setCart(newCart);
            }
        } else {
            setClicked(true);
        }
    };

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    return (
        <div className="product-card">
            <div className="product-image">
                <h1>
                    {product.header.length > 15
                        ? product.header.slice(0, 15) + '...'
                        : product.header}
                </h1>
            </div>
            <div className="product-info">
                <p>
                    {product.title.length > 200
                        ? product.title.slice(0, 200) + '...'
                        : product.title}
                </p>

                <div className="product-rating">
                    <ReactStars
                        count={rating > 0 ? rating : 5}
                        onChange={ratingChanged}
                        size={24}
                        color2={'#ffd700'}
                    />
                </div>
                <div className="product-buttons">
                    <button
                        type="button"
                        className="add-to-cart-button"
                        onClick={handleAddToCart}
                        disabled={isInCart}
                    >
                        {isInCart ? 'In Cart' : 'Add to Cart'}
                    </button>
                    <button
                        type="button"
                        className="add-to-wishlist-button"
                        onClick={handleAddToWishlist}
                        disabled={isInWishlist}
                    >
                        {isInWishlist ? 'In Wishlist' : 'Add to Wishlist'}
                    </button>
                    <button
                        type="button"
                        style={{
                            backgroundColor: 'yellow',
                            color: 'black',
                            paddingLeft: '25px',
                            paddingRight: '25px',
                        }}
                    >
                        <FaRegComment />
                    </button>
                </div>
            </div>
            {relatedProducts && relatedProducts.length > 0 && (
                <div className="related-products">
                    <button type="button" onClick={handleServiceOrder}>
                        Order Service
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProductCard;
