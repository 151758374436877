// @ts-nocheck
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './../features/Main/Home/Home';
import NotFound from './../features/errors/NotFound';
import Register from '../features/Main/Form/Register';
import ContactUs from '../features/Main/Form/ContactUs';
import BuzzKillers from '../features/Main/FreeKillers/BuzzKillers';
import MarketPlace from '../features/Main/Marketplace/MarketPlace';
import GettingStarted from '../features/Main/GettingStarted/GettingStarted';
import Login from '../features/Main/Form/Login';

const Dashboard = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/free-killers" element={<BuzzKillers />} />
                <Route path="/marketplace" element={<MarketPlace />} />
                <Route path="/start" element={<GettingStarted />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default Dashboard;
