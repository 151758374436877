
    // Interview Preparation Services
    export const services  = [{
        id: 1,
        header: 'Data-Driven Interview Preparation',
        title: 'Data-Driven Interview Preparation: Strategies for Success in Your Dream Job',
    },
    {
        id: 2,
        header: 'Analyzing Your Way to Interview Success',
        title: 'Analyzing Your Way to Interview Success: A Guide to Preparing for Your Dream Job',
    },
    {
        id: 3,
        header: 'Preparing for the Perfect Interview',
        title: 'Preparing for the Perfect Interview: Leveraging Data and Analytics to Land Your Dream Job',
    },
    {
        id: 4,
        header: 'Using Data to Your Advantage',
        title: 'Using Data to Your Advantage: Techniques for Excelling in Your Dream Job Interview',
    },
    {
        id: 5,
        header: 'Interview Mastery Through Data Analysis',
        title: 'Interview Mastery Through Data Analysis: Insights and Strategies for Job Seekers',
    },
    {
        id: 6,
        header: 'The Science of Interviewing',
        title: 'The Science of Interviewing: How Data Can Help You Secure Your Dream Job',
    },
    {
        id: 7,
        header: 'From Data to Job Offer',
        title: 'From Data to Job Offer: How Analytics Can Improve Your Interview Performance',
    },

    // Portfolio Services
    {
        id: 8,
        header: 'Portfolio Reviews',
        title: 'Portfolio Reviews: Many companies and professional organizations offer portfolio review services, where experts in the industry provide feedback on your work and help you improve your portfolio for your job search.',
    },
    {
        id: 9,
        header: 'Personal Branding',
        title: 'Personal Branding: Creating a cohesive personal brand is essential when developing a portfolio. Many branding services can help you identify and communicate your unique value proposition to potential employers.',
    },
    {
        id: 10,
        header: 'Content Creation',
        title: "Content Creation: For individuals who don't have the time or expertise to create their portfolio themselves, content creation services can help. These services can help create written and visual content that showcases your skills and accomplishments.",
    },
    {
        id: 11,
        header: 'Website Development',
        title: 'Website Development: Many professionals choose to host their portfolio on their own website. Website development services can help you create a clean and modern site that showcases your portfolio in a professional manner.',
    },
    {
        id: 12,
        header: 'Customized Portfolio Templates',
        title: 'Customized Portfolio Templates: For those who want to create their portfolio themselves, customized portfolio templates can help simplify the process. These templates provide a professional and visually appealing framework that you can customize with your own content.',
    },

    // Github Profile Services
    {
        id: 13,
        header: 'Profile Optimization',
        title: 'Profile Optimization: Services that specialize in optimizing Github profiles can help job seekers showcase their skills and experience in a way that attracts potential employers.',
    },
    {
        id: 14,
        header: 'Project Showcase',
        title: 'Project Showcase: Github is a great platform for displaying your coding projects. Services that specialize in project showcase can help you create visually appealing project descriptions and highlight your key achievements.',
    },
    {
        id: 15,
        header: 'Collaboration Tools',
        title: 'Collaboration Tools: Github is also a collaboration platform. Services that specialize in collaboration tools can help you learn how to work collaboratively with other developers and showcase this skill on your Github profile.',
    },
    {
        id: 16,
        header: 'Code Reviews',
        title: 'Code Reviews: Github is a great platform for',
    },
    {
        id: 17,
        header: 'Github Profile Reviews',
        title: 'Github Profile Reviews: Github is a great platform for showcasing your coding skills and experience. Services that specialize in Github profile reviews can help you improve your profile and increase your chances of landing your dream job.',
    },
    {
        id: 18,
        header: 'Github Profile Optimization',
        title: 'Github Profile Optimization: Github is a great platform for showcasing your coding skills and experience. Services that specialize in Github profile optimization can help you improve your profile and increase your chances of landing your dream job.',
    },
    {
        id: 19,
        header: 'Github Profile Optimization',
        title: 'GitHub Profile Optimization: Optimize your GitHub profile to highlight your skills and expertise in the best possible way.',
    },
    {
        id: 20,
        header: 'GitHub Repository Management',
        title: 'GitHub Repository Management: Keep your GitHub repositories organized, up-to-date, and maintain documentation with repository management services.',
    },
    {
        id: 21,
        header: 'GitHub Pages',
        title: 'GitHub Pages: Create a professional website to showcase your work and skills with GitHub Pages.',
    },
    {
        id: 22,
        header: 'GitHub Profile Analysis',
        title: 'GitHub Profile Analysis: Analyze your GitHub profile to identify areas of improvement and learn how to improve your profile.',
    },

    {
        id: 22,
        header: 'Interview Coaching',
        title: 'Interview Coaching: Professional interview coaches can provide one-on-one coaching sessions to help job seekers improve their interview skills and prepare for their dream job interview.',
    },
    {
        id: 23,
        header: 'Mock Interviews',
        title: 'Mock Interviews: Mock interviews provide job seekers with a simulation of the interview experience. This service helps job seekers practice their interview skills and receive feedback on their performance.',
    },
    {
        id: 24,
        header: 'Video Interview Practice',
        title: 'Video Interview Practice: With many companies conducting video interviews, this service offers job seekers the opportunity to practice and improve their video interviewing skills.',
    },
    {
        id: 25,
        header: 'Interview Feedback',
        title: 'Interview Feedback: After completing a job interview, feedback from a professional can provide valuable insight into areas of improvement for future interviews.',
    },
    {
        id: 26,
        header: 'Salary Negotiation',
        title: 'Salary Negotiation: Services that specialize in salary negotiation can help job seekers negotiate their salary and benefits package to ensure they receive fair compensation for their work.',
    },
];
