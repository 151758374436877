// @ts-nocheck
import React, { useState } from 'react';
import Navigation from '../../common/Navigation';
import Footer from '../../common/Footer';
import styles from './contactus.module.css';
import registireImg from './../../assets/devdoctorslogo2.svg';
import { FaFacebook, FaGithub } from 'react-icons/fa';
import GoogleLogin from 'react-google-login';
import { loginProvider } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import GitHubLogin from 'react-github-login';

const Register = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [error, setError] = useState(false);

    const handleGoogleSuccess = (response) => {
        setUser(response.profileObj);
        dispatch(loginProvider(response.profileObj));
    };

    const handleGoogleFailure = (response) => {
        response ? response = true : false
        setError(response);
    };

    const handleGithubSuccess = (response) => {
        setUser(response.profileObj);
        dispatch(loginProvider(response.profileObj));
        setError(false);
    };

    const handleGithubFailure = (response) => {
        response ? response = true : false
        setError(response);
    };

    return (
        <div className={`${styles.register_form}`}>
            <Navigation />
            {user && !error && (
                <div>
                    <h1>{user.name}</h1>
                    <img src={user.imageUrl} alt={user.name} />
                </div>
            )}
            <div className={`${styles.form_container}`}>
                <div className={`${styles.form_box}`}>
                    <h3>Create Your Account</h3>
                    <div className={`${styles.signin_button_box}`}>
                        <button type="button" className={`${styles.signin_button_facebook}`}>
                            <FaFacebook />
                            Facebook
                        </button>
                        <GitHubLogin
                            clientId="ea2e280ced9d35004ca9"
                            onSuccess={handleGithubSuccess}
                            onFailure={handleGithubFailure}
                            className={`${styles.signin_button_github}`}
                        > <button style={
                            {
                                backgroundColor: 'white',
                                color: 'black',
                                border: 'none',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                            }
                        }>
                                <FaGithub />
                            </button>
                        </GitHubLogin>
                        <GoogleLogin
                            clientId="YOUR_CLIENT_ID"
                            buttonText="Login with Google"
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                            className={`${styles.signin_button_google}`}
                        />
                    </div>
                    <div className={`${styles.signin_or_email}`}>
                        <p>Or register with email</p>
                    </div>
                    <form>
                        <input type="text" name="name" placeholder="Name" />
                        <input type="text" name="email" placeholder="Email" />
                        <div className={`${styles.input_box}`}>
                            <input type="password" name="password" placeholder="Password" />
                            <input
                                type="password"
                                name="confirm_password"
                                placeholder="Repeat Password"
                            />
                        </div>
                        <div className={`${styles.finished_box}`}>
                            <div className={`${styles.check_box}`}>
                                <input type="checkbox" name="checkbox" />
                                <label htmlFor="checkbox">
                                    I agree to the terms and conditions
                                </label>
                            </div>
                            <button type="submit" className={`${styles.continue_button}`}>
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
                <div className={`${styles.form_content}`}>
                    <h3>Lorem ipsum dolor sit</h3>
                    <p>consectetur adipiscing elit. Suspendisse sed urna in.</p>
                    <img src={registireImg} alt="register" />
                </div>
            </div>
            <Footer />
        </div>
    );

};

export default Register;
