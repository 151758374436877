// @ts-nocheck
import React from 'react'
import Footer from '../../common/Footer';
import Navigation from '../../common/Navigation';
import styles from './contactus.module.css';

const ContactUs = () => {
  return (
      <div className={styles.ContactUs}>
          <Navigation />
          <div className={styles.contactUs_container}>
              <div className={styles.contactUs_content_left}>
                  <p>
                      {`Have a question or want to learn more about our products and
                      services? Contact us today using the form below and a member of our
                      team will get back to you as soon as possible. We're here to help!`}
                  </p>
              </div>
              <div className={styles.contactUs_content}>
                  <h1>Contact Us</h1>
                  <p>
                      Aenean sit amet magna vel magna fringilla fermentum. Donec sit amet
                      nulla sed arcu pulvinar commodo id ligula.
                  </p>

                  <form>
                      <div>
                          <input
                              type="text"
                              name="name"
                              placeholder="What is your name? *"
                          />
                          <input
                              type="email"
                              name="email"
                              placeholder="What it your email? *"
                          />
                      </div>
                      <div>
                          <input
                              type="number"
                              name="number"
                              placeholder="What is your phone number?"
                          />
                          <input
                              type="text"
                              name="companiy"
                              placeholder="What is your company?"
                          />
                      </div>
                      <textarea
                          name="message"
                          cols="30"
                          rows="10"
                          placeholder="Write your message here"
                      ></textarea>
                      <div className={`${styles.checkBox} ${styles.checkBoxContack}`}>
                          <input type="checkbox" name="checkbox" />
                          <label htmlFor="checkbox">
                              I agree to the terms and conditions
                          </label>
                      </div>
                      <button type="submit" className={`${styles.sendMessage}`}>
                          Send Messages
                      </button>
                  </form>
              </div>
          </div>
          <Footer />
      </div>
  );
}

export default ContactUs